import React, { useState } from "react"

import "../styles/contact.css"
import "../styles/tags-input.scss"
import styled from "styled-components"
import { Button, Heading } from "rebass"
import { Input, Label, Textarea } from "@rebass/forms"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faMinusCircle,
  faPaperPlane,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons"
import ReactTagInput from "../react-tag-input/index"
import { useI18next } from "gatsby-plugin-react-i18next"
import {
  faGithubAlt,
  faGoodreads,
  faLinkedinIn, faMedium,
  faStackOverflow
} from "@fortawesome/free-brands-svg-icons"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const Contact = () => {
  const [state, setState] = useState({})
  const [technologies, setTechnologies] = useState([])
  const { t } = useI18next()

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...state,
        technologies: technologies,
      }),
    })
      .then(() => {
        window.location.pathname = "/thanks"
      })
      .catch(() => {
        window.location.pathname = "/500"
      })

    e.preventDefault()
  }

  return (
    <>
      <ContactLayout>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="contact-form" value="contact" />

          <Heading
            id={"go"}
            color={"rgba(246,246,246,0.92)"}
            fontSize={[5, 5, 5, 6]}
            mt={2}
            mb={3}
            fontFamily="monospace"
          >
            {t("contact.title")} <FontAwesomeIcon icon={faPaperPlane} />
          </Heading>
          <Label htmlFor="name">{t("contact.name.label")}</Label>
          <Input
            name={"name"}
            type="text"
            placeholder={t("contact.name.placeholder")}
            style={{
              borderRadius: 16,
              marginBottom: 16,
            }}
            onChange={handleChange}
          />
          <Label htmlFor="email">{t("contact.email.label")}</Label>
          <Input
            name={"email"}
            type="email"
            placeholder={t("contact.email.placeholder")}
            style={{
              borderRadius: 16,
              marginBottom: 16,
            }}
            onChange={handleChange}
          />
          <Label>{t("contact.technologies.label")}</Label>
          <input hidden name="technologies" value={technologies} />
          <ReactTagInput
            tags={technologies}
            onChange={newTechnologies => setTechnologies(newTechnologies)}
            removeOnBackspace={true}
            buttonVariant={true}
            addButtonText={() => <FontAwesomeIcon icon={faPlusCircle} />}
            removeButtonText={() => <FontAwesomeIcon icon={faMinusCircle} />}
            placeholder={t("contact.technologies.placeholder")}
            buttonStyle={{
              background: "none",
              border: "none",
              padding: "0 5px",
              fontSize: "20px",
              color: "white",
              textDecoration: "none",
              cursor: "pointer",
            }}
          />
          <Label>{t("contact.message.label")}</Label>
          <Textarea
            name={"message"}
            placeholder={t("contact.message.placeholder")}
            style={{
              borderRadius: 16,
              marginBottom: 32,
              height: 200,
            }}
            onChange={handleChange}
          />
          <StyledButton type="submit">
            GO <FontAwesomeIcon className={"arrow"} icon={faArrowRight} />
          </StyledButton>
        </form>
        <SocialMediaLayout>
          <li>
            <a
              target={"__blank"}
              href={"https://alramalho.medium.com"}
            >
              <FontAwesomeIcon icon={faMedium} />
            </a>
          </li>
          <li>
            <a
              target={"__blank"}
              href={"https://www.linkedin.com/in/alexandre-ramalho-265186150/"}
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </li>
          <li>
            <a target={"__blank"} href={"https://github.com/alramalho"}>
              <FontAwesomeIcon icon={faGithubAlt} />
            </a>
          </li>
          <li>
            <a
              target={"__blank"}
              href={"https://stackoverflow.com/users/8926435/alexandre-ramalho"}
            >
              <FontAwesomeIcon icon={faStackOverflow} />
            </a>
          </li>
          <li>
            <a target={"__blank"} href={"https://www.goodreads.com/alramalho"}>
              <FontAwesomeIcon icon={faGoodreads} />
            </a>
          </li>
        </SocialMediaLayout>
      </ContactLayout>
    </>
  )
}

const ContactLayout = ({ children }) => (
  <>
    <OuterLayout>
      <ContactHeader />
      <InnerLayout>{children}</InnerLayout>
    </OuterLayout>
  </>
)

const ContactHeader = () => (
  <div>
    <svg
      id=""
      preserveAspectRatio="xMidYMax meet"
      className="svg-separator"
      viewBox="0 0 1600 100"
      style={{ display: "block" }}
      data-height="100"
    >
      <path
        className=""
        style={{ opacity: 1, fill: `#273a4c` }}
        d="M-40,71.627C20.307,71.627,20.058,32,80,32s60.003,40,120,40s59.948-40,120-40s60.313,40,120,40s60.258-40,120-40s60.202,40,120,40s60.147-40,120-40s60.513,40,120,40s60.036-40,120-40c59.964,0,60.402,40,120,40s59.925-40,120-40s60.291,40,120,40s60.235-40,120-40s60.18,40,120,40s59.82,0,59.82,0l0.18,26H-60V72L-40,71.627z"
      />
      <path
        className=""
        style={{ opacity: 1, fill: `#2a3f54` }}
        d="M-40,83.627C20.307,83.627,20.058,44,80,44s60.003,40,120,40s59.948-40,120-40s60.313,40,120,40s60.258-40,120-40s60.202,40,120,40s60.147-40,120-40s60.513,40,120,40s60.036-40,120-40c59.964,0,60.402,40,120,40s59.925-40,120-40s60.291,40,120,40s60.235-40,120-40s60.18,40,120,40s59.82,0,59.82,0l0.18,14H-60V84L-40,83.627z"
      />
      <path
        className=""
        style={{ fill: `rgb(34, 49, 63)` }}
        d="M-40,95.627C20.307,95.627,20.058,56,80,56s60.003,40,120,40s59.948-40,120-40s60.313,40,120,40s60.258-40,120-40s60.202,40,120,40s60.147-40,120-40s60.513,40,120,40s60.036-40,120-40c59.964,0,60.402,40,120,40s59.925-40,120-40s60.291,40,120,40s60.235-40,120-40s60.18,40,120,40s59.82,0,59.82,0l0.18,138H-60V96L-40,95.627z"
      />
    </svg>
  </div>
)

const OuterLayout = styled.div`
  background-color: #22313f;
  position: relative;
`
const InnerLayout = styled.div`
  margin: 0 auto;
  color: ${`rgba(246, 246, 246, 0.92)`};
  text-align: center;
  max-width: 600px;
  padding: 3rem 2.45rem;
`

const StyledButton = styled(Button)`
  width: 300px;
  max-width: 100%;
  cursor: pointer !important;
  background: transparent !important;
  border: 3px solid rgba(246, 246, 246, 0.92) !important;
  border-radius: 25px !important;
  font-weight: 600 !important;
  transition: 0.15s !important;

  :hover {
    background: rgba(246, 246, 246, 0.92) !important;
    color: rgba(120, 120, 120, 0.92);
  }

  :hover > .arrow {
    transform: translate(5px) !important;
  }
`
const SocialMediaLayout = styled.ul`
  list-style: none;
  font-size: 25px;
  margin: 100px 0 0 0;
  li {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    padding: 0;
    margin: 0 10px 0 10px;
    :hover a {
      color: ${`#ff5700`};
    }
  }
  a {
    display: block;
    padding-top: 7px;
    color: ${`#22313f`};
  }
`
